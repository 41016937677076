import React from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import Register from "../../components/AccountContent/Register"

const RegisterPage = ({ location }) => {
  const path = location.pathname
  
  return (
    <Layout dark bg={colors.white}>
      <Seo
        title="Register Page"
        description="In Good Company Register Page"
        fullTitle="Register Page"
        path={path}
      />
     <Register location={location} />
    </Layout>
  )
}

export default RegisterPage
