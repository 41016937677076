import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import fonts from "../../styles/fonts"

const Container = styled.div`
  margin-bottom: 1.5rem;
`
const InputBlock = styled.div`
  margin-bottom: 0.5rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:focus + span,
  input:active + span {
    opacity: 1;
    outline: 2px solid #3e3e3e;
    outline-offset: 0;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.79rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.025em;
  color: #8c8984;
`
const Input = styled.input`
  ${fonts.openSansRegular};
  color: #1c1a17;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #d9d5d0;
  background-color: #fffefc;
  padding: 0.75rem 1rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;

  &::placeholder {
    ${fonts.openSansRegular};
    font-size: 1rem;
    line-height: 1.5;
  }

  &:focus {
    outline: 2px solid #3e3e3e;
    outline-offset: 0;
  }
`

const Error = styled.span`
  color: #e61c1c;
  font-size: 0.889rem;
  line-height: 1.5;
  ${fonts.openSansRegular};
  display: block;
`

const Information = styled.span`
  color: #8c8984;
  font-size: 0.889rem;
  display: inline-block;
  margin-top: 0.25rem;
  line-height: 1.5;
  ${fonts.openSansRegular};
`

const AccountInputField = ({
  data,
  name,
  placeholder,
  label,
  handleChange,
  optional,
  type = "text",
  error,
  information,
  reset,
  setReset,
  limit,
  cardNumber
}) => {
  const [value, setValue] = useState(data[name])

  useEffect(() => {
    handleChange(name, value)
  }, [value])

  useEffect(() => {
    if (reset === true) {
      setValue(data[name])
      setReset(false)
    }
  }, [reset])

  const changeValue = (e) => {
    if(cardNumber) {
      changeCardNumberValue(e)
    } else {
      if (limit) {
        if (e.target.value.length <= limit) {
          setValue(e.target.value)
        }
      } else {
        setValue(e.target.value)
      }
    }
  }

  const changeCardNumberValue = (e) => {
    const regex = /^\d$/; 
    let targetValue = e.target.value
    let valueLength = value.length
    const targetValueLength = targetValue.length

    if(targetValueLength <= 19) {
      if(targetValueLength > valueLength) {
        if(!regex.test(targetValue.slice(-1))) {
          return
        }
  
        if(targetValueLength === 4 || targetValueLength === 9 || targetValueLength === 14) {
          targetValue = targetValue + " ";
        }
      }
      setValue(targetValue)
    }
  }

  return (
    <Container>
      <InputBlock>
        {label && <Label>{label}</Label>}
        <Input
          type={type}
          name={name}
          isOptional={optional}
          onChange={changeValue}
          placeholder={placeholder}
          value={value}
          autoComplete="off"
        />
      </InputBlock>
      {information && <Information>{information}</Information>}
      {error && <Error>{error}</Error>}
    </Container>
  )
}

AccountInputField.propTypes = {
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  information: PropTypes.string,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
  cardNumber: PropTypes.bool,
  limit: PropTypes.number,
}

export default AccountInputField
