import React from "react"
import styled, { keyframes } from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"

const up = keyframes`
    0% {
        transform: translateY(250%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`

const ErrorWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
  animation: 0.2s ${up} cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2000;
  padding: 0.75rem;
  @media (min-width: ${breakpoints.md}) {
    top: 40px;
    padding: 0;
  }
`

const Error = styled.div`
  background: #ffd6d6;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.75rem;
  margin: 0 auto;
  span {
    margin-right: 0;
    padding-right: 0.75rem;
    color: #e61c1c;
    ${fonts.openSansRegular};
    font-size: 0.889rem;
    line-height: 1.5;
  }
`

const ErrorPopup = ({ children }) => {
  return (
    <ErrorWrapper>
      <Error>
        <span>{children}</span>
      </Error>
    </ErrorWrapper>
  )
}

export default ErrorPopup
