import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Link } from "gatsby"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"

export const H2 = styled.h2`
  margin: 0;
  padding: 0;
  padding-bottom: 1.5rem;
  margin-bottom: 0.75rem;
  font-size: 1.602rem;
  line-height: 1.25;
  ${fonts.workSansBold};
  color: #090602;
`
const AccountButtonStyle = `
    width: 100%;
    margin: 0;
    display: inline-block;
    position: relative;
    height: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;
    background-color: #F2EFEB;
    padding: 0.75rem 1.25rem;
    text-align: center;
    font-size: 0.889rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.025em;
    text-decoration: none;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
`

const AccountButtonDarkStyle = `
    ${AccountButtonStyle};
    background-color: #1c1a17;
    color: #fffefc;
    &:hover {
        background-color: #000000;
    }
`

const AccountButtonLightStyle = `
    ${AccountButtonStyle};
    color: #090602;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
`

export const AccountButtonDark = styled.button`
  ${AccountButtonDarkStyle};
`
export const AccountButtonDarkLink = styled(Link)`
  ${AccountButtonDarkStyle};
`
export const AccountButtonLight = styled.button`
  ${AccountButtonLightStyle};
`
export const AccountButtonLightLink = styled(Link)`
  ${AccountButtonLightStyle};
`

export const PopupFormContainer = styled.div`
  padding: 0.5rem 1.5rem 1rem;
  width: 100%;
  max-width: ${breakpoints.sm};
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    max-width: unset;
    padding: 0.5rem 1.5rem 1rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: unset;
    padding: 0.5rem 2rem 1rem;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: unset;
    padding: 0.5rem 2.5rem 1rem;
  }
`

export const PopupHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  /* width: 100%; */
`

export const PopupTitle = styled.h3`
  ${fonts.workSansBold};
  color: #090602;
  line-height: 1.5;
  font-size: 1.266rem;
`
export const PopupCloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
  color: #090602;
  cursor: pointer;
  hover {
    color: ${colors.royalBlue};
  }
`
export const FormPopupOverlay = styled.div`
  z-index: 1000;
  inset: 0;
  position: fixed;
  background: rgba(28, 26, 23, 0.5);
  width: 100%;
  overflow-y: auto;
  display: ${(props) => (props.open ? "flex" : "none")};
`
export const FormPopup = styled.div`
  margin: auto;
  width: 100%;
  overflow-y: ${(props) => (props.noScroll ? "auto" : "scroll")};
  background-color: #faf8f5;
  @media (min-width: ${breakpoints.md}) {
    max-width: 32rem;
    max-height: 80vh;
    border-radius: 0.25rem;
  }
`

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Info = styled.p`
  ${fonts.workSansRegular};
  font-size: 0.889rem;
  line-height: 1.5;
  color: #8c8984;
  margin: 0;
`

export const LeftPadContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${breakpoints.md}) {
    padding-left: 1.5rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 2rem;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding-left: 2.5rem;
  }
`

export const H1 = styled.h1`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
    font-size: 1.802rem;
    line-height: 1.5;
    ${fonts.workSansBold};
    color: #090602;
    margin-bottom: 2rem;
  }
`
