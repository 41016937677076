import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { Container } from "styled-bootstrap-grid"
import { MOBILE_HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT } from "../Layout/Layout"

const PageContainer = styled(Container)`
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
  margin-top: ${MOBILE_HEADER_HEIGHT}px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${DESKTOP_HEADER_HEIGHT}px;
  }
`

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 1.5rem 6rem;
  width: 100%;
  @media (min-width: ${breakpoints.sm}) {
    max-width: 576px;
  }
  @media (min-width: ${breakpoints.md}) {
    max-width: 28rem;
    padding-top: 6rem;
  }
`

const PageWrapper = ({ children }) => {
  return (
    <PageContainer>
      <Content>{children}</Content>
    </PageContainer>
  )
}

export default PageWrapper
