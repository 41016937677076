const checkEmptyFields = (
  formData,
  emptyFields,
  setEmptyFields,
  optionals = []
) => {
  let hasEmpty = false
  const formFields = Object.keys(formData)
  const updatedEmptyFields = { ...emptyFields }

  formFields.forEach((formField) => {
    if (formData[formField] === "" && !optionals.includes(formField)) {
      updatedEmptyFields[formField] = true
      hasEmpty = true
    } else {
      updatedEmptyFields[formField] = false
    }
  })
  setEmptyFields(updatedEmptyFields)
  return hasEmpty
}

const validateEmail = (email) => {
  const valid = String(email)
    .toLowerCase()
    .match(
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    )
  return valid ? true : false
}

const validateForm = (
  formData,
  fieldErrors,
  setFieldErrors,
  optionals = []
) => {
  let hasError = false
  const formFields = Object.keys(formData)
  const updatedFieldErrors = { ...fieldErrors }

  formFields.forEach((formField) => {
    if (formData[formField] === "" && !optionals.includes(formField)) {
      updatedFieldErrors[formField] = "This field is required"
      hasError = true
    }
  })
  setFieldErrors(updatedFieldErrors)
  return hasError
}

const splitCamelCase = str => str.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);

const validateFormFields = (formData, fieldErrors, setFieldErrors, optionals = []) => {
  const formFields = Object.keys(formData)
  let hasError = false 
  const updatedFieldErrors = { ...fieldErrors }
  formFields.forEach((formField) => {
    if (formData[formField].trim() === "" && !optionals.includes(formField)) {
      let fieldName = splitCamelCase(formField)

      if(formField === "email") {
        fieldName = "e-mail"
      }
      if(formField === "password2") {
        fieldName = "password"
      }
      if(formField === "number") {
        fieldName = "card number"
      }

      if(formField === "expMonth") {
        fieldName = "card expiry date"
      }
      if(formField === "cvc") {
        fieldName = "card security code"
      }

      updatedFieldErrors[formField] =  `Please enter your ${fieldName}.`
      hasError = true
    }
  })
  setFieldErrors(updatedFieldErrors)
  return hasError
}

const luhnCheck = (cardNumber) => {
  if(!cardNumber || typeof cardNumber !== "string" ) {
    return false
  }
  let num = cardNumber.replaceAll(" ", "")
  let arr = (num + '')
    .split('')
    .reverse()
    .map(x => parseInt(x));
  let lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);
  sum += lastDigit;
  return sum % 10 === 0;
};

module.exports = {
  checkEmptyFields: checkEmptyFields,
  validateEmail: validateEmail,
  validateForm: validateForm,
  validateFormFields: validateFormFields,
  luhnCheck: luhnCheck
}
