/* global fbq */

import React, { useState, useEffect } from "react"
import * as queryString from "query-string"
import styled from "styled-components"
import PageWrapper from "./PageWrapper"
import { AccountButtonDark, AccountButtonLightLink, H2 } from "./AccountStyles"
import AccountInputField from "./AccountInputField"
import { useUserContext } from "../../context/UserContext"
import {
  validateEmail,
  validateFormFields,
} from "../../services/account/popupFormHelper"
import { registerUser } from "../../services/user/user"
import ErrorPopup from "./ErrorPopup"
import { Link } from "gatsby"
import fonts from "../../styles/fonts"
import OnClickButton from "../Core/OnClickButton"
import { hashEmail, hashName, hashPhoneNumber } from "src/services/util/hash"
import axios from "axios"

const ButtonWrapper = styled.div`
  margin: 1.5rem 0;
  button {
    width: 100%;
  }
`

const emailTakenError = "An account already exists for this email"
const invalidEmailError = "Please enter a valid email address"
const passwordMatchError = "Passwords do not match"
const shortPasswordError = "Password must be at least 10 characters."

const Register = ({ location }) => {
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  var firstNamePlaceHolder
  var lastNamePlaceHolder
  var emailPlaceHolder
  const params = queryString.parse(location.search)
  if (params.firstname) {
    firstNamePlaceHolder = params.firstname
  }
  if (params.lastname) {
    lastNamePlaceHolder = params.lastname
  }
  if (params.email) {
    emailPlaceHolder = params.email
  }
  const [formData, setFormData] = useState({
    firstName: firstNamePlaceHolder ?? "",
    lastName: lastNamePlaceHolder ?? "",
    email: emailPlaceHolder ?? "",
    password: "",
    password2: "",
  })

  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  })

  const { user, setUser } = useUserContext()
  const [formError, setFormError] = useState(null)

  const handleChange = (name, value) => {
    let updatedFormData = formData

    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const validatePasswords = () => {
    if (formData["password"] !== formData["password2"]) {
      const newErrors = { ...fieldErrors, password2: passwordMatchError }
      setFieldErrors(newErrors)
      return true
    }
    if (formData.password.length < 10) {
      const newErrors = { ...fieldErrors, password: shortPasswordError }
      setFieldErrors(newErrors)
      return true
    }
    return false
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)
    setFieldErrors({
      email: "",
      password: "",
    })
    let hasError = validateFormFields(formData, fieldErrors, setFieldErrors)

    if (fieldErrors.email.trim().length > 0) {
      let validEmail = validateEmail(formData.email)
      if (!validEmail) {
        hasError = true
        const newFieldErrors = { ...fieldErrors, email: invalidEmailError }
        setFieldErrors(newFieldErrors)
      }
    }

    if (
      formData.password.trim().length > 0 &&
      formData.password2.trim().length > 0
    ) {
      const hasPasswordError = validatePasswords()
      if (hasPasswordError) {
        hasError = true
      }
    }

    if (hasError) {
      return
    }

    try {
      const registrationData = formData
      const password2 = registrationData["password2"]
      delete registrationData["password2"]

      const registeredUser = await registerUser(registrationData)
      if (registeredUser?.email?.message === "Already exists") {
        setUser(null)
        const recoveredFormData = { ...formData, password2: password2 }
        setFormData(recoveredFormData)
        setFormError(emailTakenError)
      } else {
        setUser(registeredUser)
        try {
          if (registeredUser.email) {
            const em = hashEmail(registeredUser.email)
            const fn = hashName(registeredUser.first_name)
            const ln = hashName(registeredUser.last_name)
            function generateEventId() {
              return Math.floor(Math.random() * 1e16)
            }
            const eventId = generateEventId()
            //fbq call for account creation
            if (typeof fbq === "function") {
              fbq("track", "CompleteRegistration", {
                content_name: "Account Creation",
                status: "Successful",
                
                user_data: {
                  em,
                  fn,
                  ln,
                }, 
              }, {eventID: eventId})
            }
            let conversionURL = "/.netlify/functions/metaConversion"
            let conversionData = {
              event_id: eventId,
              em,
              fn,
              ln,
              event_name: "CompleteRegistration",
            }

            //capi call for account creation
            try {
              const conversionResponse = axios
                .post(conversionURL, conversionData)
                .catch((e) => {
                  console.error("Error sending meta conversion data: " + e)
                })
            } catch (e) {
              console.log(e)
            }
          }
        } catch (e) {
          alert("error:" + JSON.stringify(e))
        }
        window.location.href = "/account"
      }
    } catch (error) {
      setUser(null)
      setFormError("An error occured, please try again.")
      console.error(error)
    }
  }

  useEffect(() => {
    let errorTimeOut
    if (formError) {
      errorTimeOut = setTimeout(() => {
        setFormError(null)
      }, 3000)
    }
    return () => {
      clearTimeout(errorTimeOut)
    }
  }, [formError])

  return (
    <PageWrapper>
      <H2>Create Account</H2>
      <form onSubmit={handleSubmit} novalidate>
        <AccountInputField
          data={formData}
          name="firstName"
          placeholder="First Name"
          handleChange={handleChange}
          error={fieldErrors.firstName}
          label="First Name"
        />
        <AccountInputField
          data={formData}
          name="lastName"
          placeholder="Last Name"
          handleChange={handleChange}
          error={fieldErrors.lastName}
          label="Last Name"
        />
        <AccountInputField
          data={formData}
          name="email"
          placeholder="E-mail"
          handleChange={handleChange}
          type="email"
          error={fieldErrors.email}
          label="E-mail"
        />
        <AccountInputField
          data={formData}
          name="password"
          placeholder="Create Password"
          handleChange={handleChange}
          type="password"
          error={fieldErrors.password}
          label="Password"
          information="Must include a minimum of 10 characters."
        />
        <AccountInputField
          data={formData}
          name="password2"
          placeholder="Re-type Password"
          handleChange={handleChange}
          type="password"
          error={fieldErrors.password2}
          label="Re-type Password"
        />
        <ButtonWrapper>
          <OnClickButton blue type="submit">
            Create account
          </OnClickButton>
        </ButtonWrapper>
        <AccountButtonLightLink to="/account/login">
          Log in
        </AccountButtonLightLink>
      </form>
      {formError && (
        <ErrorPopup>
          <span>{formError}</span>
        </ErrorPopup>
      )}
    </PageWrapper>
  )
}

export default Register
